import { Skill } from 'models/craftos-planner-api/skill'

/**
 * Function to resolve the query key for the customer primary contact query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerPrimaryContactQueryKey = (customerId: string): string[] => [
  'customer-primary-contact',
  customerId,
]

/**
 * Function to resolve the query key for the customer primary contact query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerDetailQueryKey = (customerId: string): string[] => [
  'customer-detail',
  customerId,
]

/**
 * Function to resolve the query key for the customer account query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerAccountQueryKey = (customerId: string): string[] => [
  'customer-account',
  customerId,
]

/**
 * Function to resolve the query key for the customer opportunity query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerOpportunityQueryKey = (customerId: string): string[] => [
  'customer-opportunity',
  customerId,
]

/**
 * Function to resolve the query key for the customer cases query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerCasesQueryKey = (customerId: string): string[] => [
  'customer-cases',
  customerId,
]

/**
 * Function to resolve the query key for the customer bank query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerBankQueryKey = (customerId: string): string[] => [
  'customer-bank',
  customerId,
]

/**
 * Function to resolve the query key for the bank data query.
 *
 * @param {string} iban The iban for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const bankDataQueryKey = (iban: string): string[] => ['bank-data', iban]

/**
 * Function to resolve the query key for the customer contacts query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerContactsQueryKey = (customerId: string): string[] => [
  'customer-contacts',
  customerId,
]

/**
 * Function to resolve the query key for the customer contacts query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerContactListQueryKey = (customerId: string): string[] => [
  'customer-contact-list',
  customerId,
]

/**
 * Function to resolve the query key for the customer documents by type query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerDocumentsByTypeQueryKey = (
  customerId: string,
  categoryTypes: string
): string[] => ['customer-documents-by-type', categoryTypes ?? '', customerId]

/**
 * Function to resolve the query key for the status that indicates whether a customer has individual documents or not.
 *
 * @param {string} customerId - The unique customer identifier.
 * @returns {string[]} The unique query key.
 */
const customerDocumentsExistsQueryKey = (customerId: string): string[] => [
  'customer-has-documents',
  customerId,
]

/**
 * Function to resolve the query key for the customer document link  query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerDocumentLinkQueryKey = (
  customerId: string,
  documentName: string
): string[] => ['customer-document-link', documentName ?? '', customerId]

/**
 * Function to resolve the query key for the customer installation query.
 *
 * @param {string} customerId The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerInstallationQueryKey = (customerId: string): string[] => [
  'customer-installation',
  customerId,
]

const customerUserByEmailQueryKey = (email: string): string[] => [
  'customer-user-by-email',
  email,
]

/**
 * Function to resolve the query key for the customers installation appointments query.
 *
 * @param {string} orderNumber The unique order number of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const installationAppointmentsQueryKey = (orderNumber: string): string[] => [
  'installation-appointments',
  orderNumber,
]

/**
 * Function to resolve the query key for a single CMS faq overview content item.
 *
 * @param {string} faqOverviewId The unique identifier of the faq overview content item.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const cmsFaqOverviewQueryKey = (faqOverviewId: string): string[] => [
  'cms-content',
  'faq-overview',
  faqOverviewId,
]

/**
 * Function to resolve the query key for a single CMS step detail item.
 *
 * @param {Skill[]} skills The list of relevant skills to query the step details for.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const cmsStepDetailQueryKey = (skills: Skill[]): string[] => [
  'cms-content',
  'step-detail',
  skills.sort().join('_'),
]

/**
 * Function to resolve the query key for the energy customer details by type query.
 *
 * @param {string} gcid The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerEnergyDetailsQueryKey = (gcid: string): string[] => [
  'customer-energy-details',
  gcid,
]

/**
 * Function to resolve the query key for the energy customer status by type query.
 *
 * @param {string} gcid The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerEnergyStatusQueryKey = (gcid: string): string[] => [
  'customer-energy-status',
  gcid,
]

/**
 * Function to resolve the query key for the energy customers meter status by type query.
 *
 * @param {string} gcid The unique identifier of the customer for that the query is performed.
 * @returns {string[]} The unique query key to use inside the query client.
 */
const customerMeterStatusQueryKey = (gcid: string): string[] => [
  'customer-energy-meter',
  gcid,
]

/**
 * Function to resolve the query key for the grid registration status query.
 *
 * @param {string} gcid - The customers unique identifier.
 * @returns {string[]} The query key.
 */
const customerGridRegistrationStatusQueryKey = (gcid: string): string[] => [
  'customer-grid-reg-status',
  gcid,
]

/**
 * Function to resolve the query key for the special buildability conditions.
 *
 * @param {string} orderNumber - The order number to check.
 * @returns {string[]} The query key.
 */
const sbcQueryKey = (orderNumber: string): string[] => [
  'buildability-sbc',
  orderNumber,
]
/**
 * Function to resolve the query key for the customer contract query.
 *
 * @param {string} gcid - The unique identifier of the customer for that the query is performed..
 * @returns {string[]} The query key.
 */
const customerContractQueryKey = (gcid: string): string[] => [
  'customer-contract',
  gcid,
]

export {
  customerPrimaryContactQueryKey,
  customerDetailQueryKey,
  customerAccountQueryKey,
  customerGridRegistrationStatusQueryKey,
  customerOpportunityQueryKey,
  customerCasesQueryKey,
  customerBankQueryKey,
  bankDataQueryKey,
  customerContactsQueryKey,
  customerContactListQueryKey,
  customerDocumentsByTypeQueryKey,
  customerDocumentsExistsQueryKey,
  cmsFaqOverviewQueryKey,
  customerDocumentLinkQueryKey,
  customerInstallationQueryKey,
  customerUserByEmailQueryKey,
  cmsStepDetailQueryKey,
  installationAppointmentsQueryKey,
  customerEnergyDetailsQueryKey,
  customerEnergyStatusQueryKey,
  customerMeterStatusQueryKey,
  sbcQueryKey,
  customerContractQueryKey,
}
